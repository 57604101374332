import React from 'react';
import { Fade, Reveal } from 'react-reveal';
import Image from '../components/Image';
import Backlight from '../images/tv-backlight.png';
import CRVideo from '../videos/cr-tv-app.mp4';
import CRVideoPH from '../images/cr-placeholder-video.png';

const CrunchyrollContent = () => { 
  return (
  <div id={'main-content'}>         
    <section className={'project-detail-hero'} >
      <h1 className={'project-name two-piece-combo'} >
        <span className={'slide-top a1'}>Crunchyroll </span> 
        <span className={'slide-top a2'}>Director </span>  
        <span className={'slide-top a3'}>&amp; 10ft UX</span>
      </h1>
    <Fade >
      <div className={'contribution two-piece-combo'} >
        <div className={'icon'} >&#xe905;</div>
        <ul>
          <li>Design Team Management</li>
          <li>Smart TV &amp; Console Design</li>
          <li>Web App Design</li>
        </ul>        
      </div>
    </Fade>
    </section>

    <Fade>
    <section className={'project-info'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          The Home of Anime
        </h2>
        <Fade fraction={.71}>
        <Image
          alt="Crunchyroll Mascot: Hime" 
          filename="cr-hime.png"
          className='cr-hime'
        />
        </Fade>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
        Crunchyroll is the most popular online anime company in the world. Every month, millions go to Crunchyroll’s website, mobile apps or TV apps to keep up with their favorite anime shows. 
        </p><p>
        I started working at Crunchyroll as a Senior Product Designer and was promoted to Director within a year. As Design Director I staffed, managed and mentored a design team that went from 5 to 12 including the addition of dedicated user researchers to the team. 
        </p>

      </div>
    </section>
    </Fade>

    <Fade>
    <section className={'project-info inbed'} >
      <div className={'project-copy two-piece-combo'} >
        <h2 className={'sub-head'} >
          10’ User Experience
        </h2>
      </div>
      <div className={'project-copy two-piece-combo'} >
        <p>
          During my time as an individual contributor, I had the fortune to redesign a majority of the apps for televisions. This included gaming console &amp; smart TV apps. It was an exciting opportunity to design for unique challenges like different interaction input devices (remote controls, game controllers) &amp; the 10’ experience.

        </p><p>
          Here is a prototype of the design:
        </p>
      </div>
    </section>
    </Fade>
    <section id={'cr-video'} className={'project-info'} >
      <div className={'one-piece'}>
        <video 
          poster={CRVideoPH}
          muted 
          preload 
          autoPlay 
          loop 
          playsInline
        >
          <source src={CRVideo} type="video/mp4" />
        </video>
        <img 
            className={'backlight'} 
            src={Backlight} 
          />
      </div>
    </section>
  </div>
  )
}

export default CrunchyrollContent;